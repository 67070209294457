/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Aug 20, 2015, 1:29:42 PM
    Author     : Sanjay Maharjan
*/

/* *{
	margin:0px auto;
	padding: 0px;
} */
/* page[size="A4"] {
    background: white;
    width: 21cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
  } */

.page div,
.page span,
.page applet,
.page object,
.page iframe,
h1,
.page h2,
.page h3,
.page h4,
.page h5,
.page h6,
.page p,
.page blockquote,
.page pre,
a,
.page abbr,
.page acronym,
.page address,
.page big,
.page cite,
.page code,
del,
.page dfn,
.page em,
.page img,
.page ins,
.page kbd,
.page q,
.page s,
.page samp,
small,
.page strike,
.page strong,
.page sub,
.page sup,
.page tt,
.page var,
b,
.page u,
.page i,
.page center,
dl,
.page dt,
.page dd,
.page ol,
.page ul,
.page li,
fieldset,
.page form,
.page label,
.page legend,
table,
.page caption,
.page tbody,
.page tfoot,
.page thead,
.page tr,
.page th,
.page td,
article,
.page aside,
.page canvas,
.page details,
.page embed,
figure,
.page figcaption,
.page footer,
.page header,
.page hgroup,
menu,
.page nav,
.page output,
.page ruby,
.page section,
.page summary,
time,
.page mark,
.page audio,
.page video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  font: inherit;
  vertical-align: baseline;
}

.page {
  line-height: 1.3;
  font-size: 14px;
  width: 211mm;
  min-height: 297mm;
  padding: 10mm;
  /* background: #cac; */
  font-family: "Times New Roman", Cambria, Cochin, Georgia, Times, serif;
}
.pageA5 {
}

.page table {
  border-collapse: collapse;
  border-spacing: 0;
}

.page-border {
  width: 212mm;
  min-height: 297mm;
  /* padding: 10mm; */
  background: white;
  font-family: "Times New Roman", Cambria, Cochin, Georgia, Times, serif;
  margin: 0 auto;
  border: 1px #d3d3d3 solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.page-container {
  width: 100%;
  /* margin: 0 auto; */
  overflow-x: auto;
}

.page .title {
  font-size: 25px;
}
.page .reg {
  text-align: right;
}
.page .sub-title {
  font-size: 20px;
}
.page hr {
  margin: 5px;
}
.page table {
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.page table.userinfo tr td.label-left {
  min-width: 90px;
}
.page table.userinfo tr td.seperator {
  width: 5px;
}
.page table.userinfo tr td.input {
  padding-left: 10px;
  width: 57%;
}
.page table.userinfo input {
  border: 0px;
  margin: 2px;
  width: 98%;
}
.page table tr td.label-right {
  min-width: 140px;
}
.page table.userinfo tr {
  line-height: 1.3;
}
.page table tr td.sm {
  padding-left: 10px;
  width: 23%;
  font-size: 16px;
}
.page table.userinfo input#sex,
table.userinfo input#age {
  border: 0px;
  margin: 2px;
  width: 80%;
}

.page .report-on {
  padding: 7px 10px;
  font-weight: bold;
  font-size: 16px;
}

.page table tr th {
  padding-left: 10px;
  font-weight: bold;
}
.page table tr td {
  padding-left: 10px;
}
.page table .sn {
  width: 40px;
  display: none;
}
.page table input {
  width: 100%;
}
.page #haematologyReport input,
.page #biochemicalReport input,
.page #serologyReport input,
.page #semenAnalysisReport input,
.page #stoolReport input,
.page #urineReport input,
.page #immunologyReport input,
.page #microbiology input {
  border: 0;
}

.page table .test {
  width: 30%;
  /*font-size:12px;*/
}
.page table .sm {
  font-size: 14px;
}
.page #haematologyReport tr,
.page #biochemicalReport tr,
.page #serologyReport tr,
.page #semenAnalysisReport tr,
.page #stoolReport tr,
.page #urineReport tr,
.page #immunologyReport tr,
.page #microbiology tr {
  line-height: 25px;
}

.page .test_sero {
  width: 20%;
}
.page .result,
.page .ref,
.page .unit,
.page .ot {
  width: 19%;
}

.page input:focus {
  background-color: #90caf9;
}
.page .repo {
  font-weight: bold;
}

.page .md {
  width: 50%;
}

.page #haematologyReport,
.page #biochemicalReport,
.page #serologyReport,
.page #semenAnalysisReport,
.page #stoolReport,
.page #immulologyReport,
.page #urineReport,
.page #microbiology {
  padding-bottom: 10px;
  font-size: 16px;
}

.page #bottom-controller table tr:first-child {
  font-weight: bold;
  background: #aaa;
}

.page #bottom-controller table tr:nth-child(even) {
  background: #ccc;
}

.page .submit {
  width: 100px;
  background: #21ca69;
  height: 25px;
}

.page .child {
  padding-left: 25px;
}
.page .parent {
}

.page .reference {
  font-size: 14px;
}

.page .strong {
  font-weight: bold;
}

.page button.finishButton {
  width: 100px;
  height: 30px;
  background-color: #21ca69;
  border: 0px;
  float: right;
}

/* Microbiology Styles */
.page .testType {
  max-width: 50%;
  min-width: 500px;
  height: 300px;
  margin: 0 auto;
}

.page .stainButton,
.page .cultureButton {
  width: 150px;
  margin: 30px;
  display: inline-block;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
}
.page .stainButton {
  background-color: #9fa8da;
}

.page .cultureButton {
  background-color: #c5cae9;
}

.page .addResultButton {
  background-color: #81c784;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  padding: 20px;
  margin: 20px;
}

.page .stainTable .dayCount {
  width: 20%;
}
.page .stainTable {
  width: 800px;
}

/*Stain Report*/
/* .page #microbiology {
} */

/* .page #microbiology .microbiologyDescription table { */
/*width: initial;*/
/* } */
.page #microbiology .microbiologyDescription .topTable td:first-child {
  width: 160px;
}
.page #microbiology .topTable .microbiologyDescription .topTable td {
  width: 400px;
}

.page #microbiology .microbiologyDescription table tr {
  margin: 10px;
}

.page #microbiology .stainTable .dayCount {
  width: 20%;
}

/* .page #microbiology .dummyTD {
  display: none;
  width: 10px;
  padding: 0px;
} */

.page #microbiology .resultLabelDiv {
  padding: 0px 10px;
}

.page #microbiology .microbiology {
  margin: 2px 14px;
  width: 98%;
}

.page #microbiology .testDayTD {
  width: 120px;
}
.page #microbiology .resultTD {
  /* width: 120px; */
  font-size: 12px;
}
.page .cultureTableDiv table {
  width: 70%;
}
.page .cultureTableDiv .culture {
  width: 49%;
  display: inline-block;
}

/*#microbiology input {*/
/*font-size: 13px;*/
/*}*/
.page .cultureTableDiv {
  padding-top: 10px;
  padding-left: 10px;
}
.page .microbiologyDescription .topTable {
  width: 100%;
}
.page .font-l,
.microbioResult {
  font-size: 14px;
}
